.container {
  position: relative;
  padding: var(--ds-space-6-x) var(--ds-space-4-x) var(--ds-space-4-x);
  background: var(--ds-color-meteorite);
  border-radius: var(--ds-space-3-x);
  color: var(--ds-color-pureWhite);
  display: flex;
  justify-content: space-between;

  @media (--s) {
    padding: var(--ds-space-6-x) var(--ds-space-8-x) var(--ds-space-6-x);
  }
  @media (--m) {
    height: 330px;
    padding-top: var(--ds-space-5-x);
  }
  @media (--l) {
    height: 361px;
    padding-bottom: var(--ds-space-5-x);
  }
}

.leftSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  @media (--m) {
    max-width: 480px;
    flex: 0 0 auto;
  }

  @media (--l) {
    padding-top: calc(var(--ds-space-5-x) * 2);
    padding-bottom: calc(var(--ds-space-5-x) * 2);
  }
}

.title {
  padding-bottom: var(--ds-space-4-x);
  margin-top: var(--ds-space-8-x);
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-xxl);
  font-weight: var(--ds-font-weight-bold);

  @media (--m) {
    font-size: 34px;
  }
}

.heading {
  padding-bottom: var(--ds-space-1-and-a-half-x);
  font-size: var(--ds-font-size-xl);
  font-weight: var(--ds-font-weight-bold);
  line-height: 32px;

  @media (--m) {
    font-size: var(--ds-font-size-xxl);
    line-height: 40px;
  }
}

.body {
  padding-bottom: var(--ds-space-2-x);
  font-size: var(--ds-font-size-m);
  line-height: 26px;

  @media (--m) {
    padding-bottom: var(--ds-space-4-x);
    font-size: var(--ds-font-size-l);
    line-height: 32px;
  }
}

.image {
  width: 408px;
  height: 265px;
}

.buttonContainer {
  width: 100%;

  @media (--s) {
    width: 220px;
  }

  @media (--m) {
    width: 228px;
  }
}
