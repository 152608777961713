.slideCard {
  --background-color: #f8f6ff;
  --title-color: #5234b6;
  --icon-color: #8f1dbf;

  display: grid;
  height: 69px;
  padding: 2rem 1rem;
  margin: 1rem 0.5rem;
  grid-template: 1fr auto / 24px auto;
  grid-template-areas:
    'icon title'
    'icon body';
  gap: 0.5rem 0.75rem;
  background-color: var(--background-color);
  border-radius: 8px;

  &:first-of-type {
    margin-left: 1rem;
    @media screen and (min-width: 1024px) {
      margin-left: 0;
    }
  }

  &:last-of-type {
    margin-right: 1rem;
    @media screen and (min-width: 1024px) {
      margin-right: 0;
    }
  }

  h3 {
    grid-area: title;
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--title-color);
    margin: 0;
    padding-top: 3px;
  }

  svg {
    grid-area: icon;
    align-self: flex-start;
    color: var(--icon-color);
  }

  p {
    grid-area: body;
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    padding-bottom: 0.688rem;
    line-height: 20px;
  }

  @media screen and (min-width: 1024px) {
    --background-color: transparent;

    max-width: 320px;
    padding: 0;
    margin: 0;

    h3 {
      text-decoration: underline;
    }
  }
}
