.uspContainerDesktop {
  display: none;

  @media screen and (min-width: 1024px) {
    display: grid;
    margin: 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
    margin-bottom: 1.5rem;
  }
}

.uspContainerMobile {
  display: block;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.carouselControls {
  padding: 1rem;
  margin-bottom: 1rem;
}

.carouselWrapper {
  margin-top: 1rem;
  @media screen and (min-width: 1024px) {
    margin-top: 2rem;
  }
}

.carouselInner {
  padding: 0;

  /** restore the wrapper's css for desktops **/
  @media screen and (min-width: 1024px) {
    padding-right: calc(32px + env(safe-area-inset-right));
    padding-left: calc(32px + env(safe-area-inset-left));
  }
}
