.subheading {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  @media (--m) {
    font-size: 22px;
    line-height: 32px;
  }
}
