.tabTitle {
  font-size: 1.125rem;
  font-weight: 700;
}

.filtersContent {
  padding: 0;

  @media only screen and (min-width: 1024px) {
    height: 450px;
    width: 100%;
  }
}
