.filters_container__wK4Fs {
  --radio-purple: #5234b6;

  background-color: #FFFFFF;
  border-radius: 18px;
  color: #656565;
  padding: 1.5rem 1.125rem;
  width: 100%;
  margin: 0 auto;
  grid-area: search;
}

  @media only screen and (min-width: 768px) {.filters_container__wK4Fs {
    padding: 1.5rem 1.125rem
}
  }

  @media only screen and (min-width: 1024px) {.filters_container__wK4Fs {
    height: 458px;
    max-width: unset;
    margin: unset;
    padding: 1.5rem 2rem 2rem
}
  }

.filters_heroFormContainer__f6AaK {
  padding: 1rem 1.125rem 0;
}

@media only screen and (min-width: 768px) {

.filters_heroFormContainer__f6AaK {
    padding: 1rem 1.125rem 0
}
  }

@media only screen and (min-width: 1024px) {

.filters_heroFormContainer__f6AaK {
    height: 430px;
    padding: 1rem 2rem 0
}
  }

.filters_heading__GjSEj {
  font-size: 24px;
  font-weight: 700;
  line-height: 2rem;
  color: #2F2F2F;
  margin-bottom: 1.5rem;
}

.filters_errorBox__65CtR {
  display: none;
}

.filters_toPriceSelect__7X__S {
  margin-bottom: 1rem;
}

.filters_radioGroup__NVcxn {
  display: inline-flex;
  padding: 1.125rem 0 1rem;
  gap: 2.5rem;
}

.filters_priceLabel__RfabW {
  white-space: nowrap;
  width: auto;
  display: flex;
  align-items: center;
  min-width: -moz-fit-content;
  min-width: fit-content;
  gap: 1rem;
}

.filters_radioInputContainer__ppiCs {
  display: flex;
  align-items: center;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.filters_radioInputContainer__ppiCs input[type='radio'] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    margin: 0;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
    background-color: #FFFFFF;
  }

.filters_radioInputContainer__ppiCs input[type='radio']::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      border: none;
    }

.filters_radioInputContainer__ppiCs input[type='radio']:not(:checked) {
      border: 1px solid black;
    }

.filters_radioInputContainer__ppiCs input[type='radio']:checked {
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--radio-purple);
    }

.filters_radioInputContainer__ppiCs input[type='radio']:checked::after {
      background-color: var(--radio-purple);
    }

@media only screen and (min-width: 1024px) {

.filters_buttonContainer__uEKld {
    margin-bottom: 1.5rem
}
  }

.filters_trustpilotOverview__HeirF {
  display: none;
}

@media only screen and (min-width: 1024px) {

.filters_trustpilotOverview__HeirF {
    display: block
}
  }

.filters_helpMeChoose__oiWpr {
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  size: 14px;
  flex-direction: column;
  line-height: 20px;
  justify-content: center;
}

@media only screen and (min-width: 480px) and (max-width: 1024px) {

.filters_helpMeChoose__oiWpr {
    flex-direction: row;
    gap: 0.5ch
}
  }

.filters_link__Z3Np0 {
  --link-color: #1565c0;

  color: var(--link-color);
  text-decoration: underline;
}

.price_toPriceSelect__CFsXg {
  margin-bottom: 1rem;
}

.price_errorBox__nLE4V {
  display: none;
}

.hero-heading_heading__grSW9 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

  @media (min-width: 1024px) {.hero-heading_heading__grSW9 {
    font-size: 42px;
    line-height: 56px
}
  }

.hero-subheading_subheading__qyRw1 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

  @media (min-width: 1024px) {.hero-subheading_subheading__qyRw1 {
    font-size: 22px;
    line-height: 32px
}
  }

.hero-link_promoLink__Fd31N {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-decoration: underline;
}

.hero-image_imageContainer__jwtkH {
  display: flex;
  margin: 0 auto;
  max-width: 70%;
}

  @media only screen and (max-width: 1024px) {.hero-image_imageContainer__jwtkH {
    display: none
}

    .hero-image_imageContainer__jwtkH.hero-image_isShownOnMobile__Uwuik {
      display: flex;
    }
  }

  @media only screen and (min-width: 1024px) {.hero-image_imageContainer__jwtkH {
    display: flex;
    max-width: unset
}
  }

/* stylelint-disable-next-line block-no-empty */
.hero-image_isShownOnMobile__Uwuik {
}

.hero_container__q0bi6 {
  position: relative;
  color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
}

.hero_background__eCd9o {
  width: 100%;
  margin-top: -120px;
  padding-bottom: 16px;
}

@media only screen and (min-width: 1024px) {

.hero_background__eCd9o {
    padding-bottom: 32px
}
  }

@media (min-width: 1024px) {

.hero_background__eCd9o {
    padding-bottom: 48px
}
  }

.hero_backgroundGradient__ODQQe {
  background-image: linear-gradient(
    180deg,
    #311b77 0%,
    #8f1dbf 48%,
    #d333ec 98%
  );
}

/* Leaving commented so if the background gradient changes in future we can switch quickly
* if needed use cx in className to combine backgroundGradientForPromo with {[styles['backgroundGradientForPromo']]: isHeroPromotional,} */
.hero_backgroundGradientForPromo__dby_9 {
  background-image: linear-gradient(180deg, #110a2a 0%, #8f1dbf 100%);
}

.hero_heroInner__pvdfy {
  max-width: 1280px;
  position: relative;
  display: grid;
  grid-template: 'content' 'search' / 1fr;
  grid-gap: 32px;
  margin: 0 auto;
  padding-top: 120px;
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-left: calc(16px + env(safe-area-inset-left));
}

@media only screen and (min-width: 768px) {

.hero_heroInner__pvdfy {
    padding-left: calc(36px + env(safe-area-inset-left));
    padding-right: calc(36px + env(safe-area-inset-left))
}
  }

@media only screen and (min-width: 1024px) {

.hero_heroInner__pvdfy {
    grid-template: 'search . content' / 390px 36px auto;
    overflow: hidden;
    gap: 0
}
  }

.hero_textAndImageContainer__qtjyO {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  display: grid;
  grid-template: 'text' 'image' / 1fr;
  align-items: center;
  grid-area: content;
  grid-gap: 32px;
  width: 100%;
}

@media only screen and (min-width: 1024px) {

.hero_textAndImageContainer__qtjyO {
    -ms-grid-row: 1;
    -ms-grid-column: 5
}
}

@media only screen and (min-width: 1024px) {

.hero_textAndImageContainer__qtjyO {
    max-height: 458px;
    margin: 0 auto
}
  }

.hero_textContainer__2MAYX {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  width: 100%;
}

@media (min-width: 1024px) {

.hero_textContainer__2MAYX {
    gap: 8px
}
  }

.hero_imageGridContainer__kHowW {
  display: grid;
}

.hero_imageGridContainer__kHowW img {
  max-height: 190px;
}

@media screen and (min-width: 1024px) {

.hero_imageGridContainer__kHowW img {
    max-height: 200px
}
  }

.hero_isMobileImageShown__WClw2 {
  gap: 32px;
}

.hero_trustpilotWrapper__yIKtS {
  padding: 1rem;
}

.hero_trustpilotMobile__0W0Oj {
  padding-top: 24px;
}

.hero-form_tabTitle__bRmgo {
  font-size: 1.125rem;
  font-weight: 700;
}

.hero-form_filtersContent__M1Aqt {
  padding: 0;
}

@media only screen and (min-width: 1024px) {

.hero-form_filtersContent__M1Aqt {
    height: 450px;
    width: 100%
}
  }

.hero-part-ex_partExContainer__psUYv {
  --radio-purple: #5234b6;

  background-color: #FFFFFF;
  border-radius: 18px;
  color: #656565;
  padding: 1.5rem 1.125rem 1rem;
  width: 100%;
  margin: 0 auto;
  grid-area: search;
}

  @media only screen and (min-width: 1024px) {.hero-part-ex_partExContainer__psUYv {
    height: 430px;
    max-width: unset;
    margin: unset;
    padding: 1.5rem 2rem 0
}
  }

.hero-part-ex_heading__5LzLn {
  font-size: 24px;
  font-weight: 700;
  line-height: 2rem;
  color: #2F2F2F;
  margin-bottom: 2rem;
}

.hero-part-ex_vrmInputLabel__o3NGn {
  --vrmInputLabel: #2f2f2f;

  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 12px;
  color: var(--vrmInputLabel);
}

.hero-part-ex_vrmInput__L_7k2 {
  --borderVrmInput: #656565;

  border: 1px solid var(--borderVrmInput);
  border-radius: 4px;
}

.hero-part-ex_partExTextList__Fwqhc {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 2.5rem;
  padding-left: 8px;
}

.hero-part-ex_partExTextListItems__m896l {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.shop-by-shape-card_card__Z3j8J {
  display: block;
  padding: 16px 8px 24px 8px;
  background-color: #FAFAFA;
  border-radius: 12px;
  box-shadow: 0 3px 10px 0 rgba(169, 169, 169, 0.302);
  text-align: center;
}

  @media (max-width: 599px) {.shop-by-shape-card_card__Z3j8J {
    padding: 12px 14px
}
  }

  @media (min-width: 768px) {.shop-by-shape-card_card__Z3j8J {
    padding: 16px 14px 25px 14px
}
  }

.shop-by-shape-card_card__Z3j8J:hover {
  background-color: var(--color-pureWhite);
}

.shop-by-shape-card_heading__tEO_q {
  margin-top: 16px;
  text-align: center;
}

.shop-by-shape-card_title__NgQpo {
  color: #2F2F2F;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.shop-by-shape-card_body__PNlr6 {
  margin-top: 2px;
  margin-bottom: 12px;
  color: #656565;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}

@media (min-width: 1024px) {

.shop-by-shape-card_body__PNlr6 {
    margin-top: 4px;
    margin-bottom: 24px
}
  }

.shop-by-shape-card_link__r4ZzX {
  color: #1565C0;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
}

.shop-by-shape-card_button__YRUpY {
  width: 33px;
  height: 40px;
  padding: 8px 16px;
}

.shop-by-shape-component_heading__r6J0b {
  margin-top: 48px;
  color: #2F2F2F;
  font-weight: 700;
  line-height: 40px;
}
  @media (min-width: 1024px) {.shop-by-shape-component_heading__r6J0b {
    margin-top: 64px
}
  }

.shop-by-shape-component_container__VXmYq {
  margin-top: 33px;
  margin-bottom: 64px;
}

@media (min-width: 1024px) {

.shop-by-shape-component_container__VXmYq {
    margin-top: 24px;
    margin-bottom: 96px
}
  }

.shop-by-shape-component_cards__fbRl_ {
  display: grid;
  border-radius: 16px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 16px;
}

@media (min-width: 768px) {

.shop-by-shape-component_cards__fbRl_ {
    -moz-column-gap: 48px;
         column-gap: 48px;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 24px
}
  }

@media (min-width: 1024px) {

.shop-by-shape-component_cards__fbRl_ {
    grid-template-columns: repeat(4, 1fr)
}
  }

.part-ex_container__IbxvE {
  margin-bottom: calc(40px * 2);
}

.part-ex_partExchangeMarketingSectionContainer__K8nns {
  position: relative;
  padding: 32px 16px;
  margin-bottom: 32px;
  background: #311C77;
  border-radius: 24px;
}

@media (min-width: 1024px) {

.part-ex_partExchangeMarketingSectionContainer__K8nns {
    height: 362px;
    padding: 0
}
  }

.part-ex_partExchangeMarketingSectionWrapper__r3zpC {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {

.part-ex_partExchangeMarketingSectionWrapper__r3zpC {
    max-width: 1024px;
    flex-direction: row;
    margin: 0 0 0 86px
}
  }

.part-ex_partExchangeMarketingSectionLeftSection__uaKZh {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 24px 0 0 0;
}

@media (min-width: 1024px) {

.part-ex_partExchangeMarketingSectionLeftSection__uaKZh {
    max-width: 416px;
    flex: 0 0 auto;
    padding: 48px 0 0 0
}
  }

.part-ex_partExchangeMarketingSectionTitle__8dbAe {
  color: #8DFFBE;
}

.part-ex_partExchangeMarketingSectionSubTitle__yjuLc {
  margin-bottom: 24px;
  color: #FAFAFA;
}

.part-ex_partExchangeMarketingSectionSubTitleSpan___VHLR {
  white-space: nowrap;
}

.part-ex_vrmInputWrapper__Zv7_A {
  margin-bottom: 40px;
}

.part-ex_vrmInputLabel__xwZj4 {
  margin-bottom: 12px;
  color: #FFFFFF;
}

.part-ex_vrmInput__GZ_78 {
  border: 1px solid #8C32BE;
  border-radius: 4px;
}

.part-ex_partExchangeMarketingSectionImage__cPISq {
  display: none;
}

@media (min-width: 1024px) {

.part-ex_partExchangeMarketingSectionImage__cPISq {
    position: absolute;
    right: 0;
    display: block;
    overflow: hidden;
    width: 410px;
    height: 362px;
    border-radius: 0 24px 24px 0
}
  }

.part-ex_findMoreButton__nwgeC {
  margin-left: 12px;
  color: #1565C0;
  text-decoration: underline;
}

.part-ex_infoWrapper__43vYa {
  text-align: center;
}

.part-ex_findMore__FQNjq {
  display: flex;
  justify-content: center;
}

.why-cinch_whyCinchContainer__SkRlD {
  padding: 64px 0 56px 0;
  margin-bottom: 64px;
  background-color: #F2F5FF;
}

  @media (min-width: 768px) {.why-cinch_whyCinchContainer__SkRlD {
    padding-bottom: 32px
}
  }

  @media (min-width: 1024px) {.why-cinch_whyCinchContainer__SkRlD {
    padding: calc(8px * 9) 0 56px;
    margin-bottom: 96px
}
  }

.why-cinch_whyCinchInner__Lfd89 {
  max-width: 1024px;
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-left: calc(16px + env(safe-area-inset-left));
  margin: 0 auto;
}

@media (min-width: 768px) {

.why-cinch_whyCinchInner__Lfd89 {
    padding-right: calc(
      calc(8px * 15) + env(safe-area-inset-left)
    );
    padding-left: calc(
      calc(8px * 15) + env(safe-area-inset-right)
    )
}
  }

@media (min-width: 1024px) {

.why-cinch_whyCinchInner__Lfd89 {
    padding-right: calc(32px + env(safe-area-inset-right));
    padding-left: calc(32px + env(safe-area-inset-left))
}
  }

.why-cinch_whyCinchHeading__9Uk4q {
  margin-bottom: 56px;
  text-align: center;
}

@media (min-width: 1024px) {

.why-cinch_whyCinchHeading__9Uk4q {
    text-align: left
}
  }

@media (min-width: 1024px) {

.why-cinch_contentWrapper__Z9BsU {
    display: flex
}
  }

.why-cinch_cardWithIcon__IIJoO {
  padding: 32px;
  margin: 0 auto 24px auto;
}

@media (min-width: 1024px) {

.why-cinch_cardWithIcon__IIJoO {
    margin: 0
}
  }

.why-cinch_whyCinchImageContainer__Yfpda {
  width: 300px;
  margin-top: 24px;
}

@media (min-width: 1024px) {

.why-cinch_whyCinchImageContainer__Yfpda {
    min-width: 360px;
    margin-left: 40px
}
  }

@media (min-width: 1440px) {

.why-cinch_whyCinchImageContainer__Yfpda {
    margin-top: 0;
    margin-right: -80px
}
  }

.why-cinch_contentSectionWrapper__lHv_d {
  margin-bottom: 0;
  text-align: center;
}

@media (min-width: 1024px) {

.why-cinch_contentSectionWrapper__lHv_d {
    text-align: left
}
  }

.why-cinch_contentSectionTitle__weM0U {
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 1.35;
}

.why-cinch_contentSectionBody__ifCtt {
  color: var(--ds--color-pureBlack);
  font-size: 18px;
}

@media (min-width: 1024px) {

.why-cinch_bulletContainer__NJx1S {
    align-self: flex-start
}
  }

@media (min-width: 1024px) {

.why-cinch_listItem__qYrT3 {
    margin-bottom: 64px
}
  }

.our-commitment-slide_ourCommitmentSlideContainer__AUh4F {
  display: flex;
  height: 450px;
  box-sizing: border-box; /* Opera/IE 8+ */
  justify-content: center;
  padding: calc(8px * 12) calc(8px * 2) 0
    calc(8px * 2);
}

  @media (min-width: 768px) {.our-commitment-slide_ourCommitmentSlideContainer__AUh4F {
    height: 400px;
    padding-top: calc(8px * 13);
    margin: 0 auto;
    text-align: center
}
  }

.our-commitment-slide_ourCommitmentSlide__26DvH {
  max-width: 630px;
}

.our-commitment-slide_title__S5oQ_ {
  color: #8DFFBE;
  font-size: 28px !important;
}

.our-commitment-slide_body__Qv9iO {
  color: #FFFFFF;
}

.our-commitment-slide_body__Qv9iO a {
    color: #FFFFFF !important;
  }

/* stylelint-disable selector-class-pattern */
.our-commitment-carousel_customPagination__pi1lX.cinch-commitment-pagination
    > .swiper-pagination-bullet {
  border-color: #FFFFFF;
}

.our-commitment-carousel_customPagination__pi1lX.cinch-commitment-pagination
    > .swiper-pagination-bullet-active {
  background: #FFFFFF;
}

.our-commitment-carousel_customPagination__pi1lX.cinch-commitment-pagination > .swiper-wrapper {
  box-sizing: inherit;
}

.our-commitment-carousel_controlsWrapper__mrp7i {
  z-index: 1;
  top: -80px;
  padding: 0 16px;
  text-align: center;
}

@media (min-width: 768px) {

.our-commitment-carousel_controlsWrapper__mrp7i {
    padding: 0
}
  }

.our-commitment-carousel_prevButton__mEHNx,
.our-commitment-carousel_nextButton__uGf6C {
  border: 1px #FFFFFF solid !important;
  color: #FFFFFF !important;
}

.wbac_innerWrapper__VgpIT {
  padding: 0 32px 0 24px;
}

  @media (min-width: 768px) {.wbac_innerWrapper__VgpIT {
    padding: unset;
    text-align: center
}
  }

.wbac_logoWrapper__xL_vn {
  margin: 0 0 96px;
}

@media (min-width: 768px) {

.wbac_logoWrapper__xL_vn {
    margin: 0 auto 96px
}
  }

.wbac_logo__nqZWj {
  width: 141px;
}

@media (min-width: 768px) {

.wbac_logo__nqZWj {
    width: 343px
}
  }

.wbac_heading__qHZwT {
  font-size: 1.75rem;
  color: #311B77;
  margin-bottom: 24px;
}

.wbac_cta__8Vq_T {
  width: 279px;
  margin: 0 auto 40px 0;
}

@media (min-width: 768px) {

.wbac_cta__8Vq_T {
    width: 343px;
    margin: 0 auto 40px
}
  }

.cards_vehicleCardList__GRHCX {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
} /* 24px - 8px padding around cards */ @media (min-width: 1024px) {.cards_vehicleCardList__GRHCX {
    grid-template-columns: repeat(3, 1fr)
}
  }

.cards_vehicleCardListItem__H0w7b {
  height: 100%;
}

.cards_vehicleCardContainer__f_zuu {
  height: 100%;
  padding: 8px;
}

.cards_findSimilarVehiclesCard__KnzTq {
  height: 100%;
  min-height: 370px;
  border-radius: 16px;
  background: #5234B6;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cards_textContainer__pA9eG {
  margin-top: 88px;
  width: 226px;
}

.cards_text__wNcPt {
  color: #FFFFFF;
  margin-top: 24px;
  font-size: 22px;
}

.cards_button__TyjoB {
  padding: 0;
  width: 100%;
  margin-bottom: 32px;
}

.carousel_controlsWrapper__QgdCf {
  margin-top: 16px;
}

  @media (min-width: 1024px) {.carousel_controlsWrapper__QgdCf {
    margin-top: 24px
}
  }

/* stylelint-disable selector-class-pattern */
.carousel_customPagination__HxY3A.recently-viewed-cars-pagination
    > .swiper-pagination-bullet,
.carousel_customPagination__HxY3A.you-might-like-pagination
    > .swiper-pagination-bullet {
  border-color: #311C77;
}

.carousel_customPagination__HxY3A.recently-viewed-cars-pagination
    > .swiper-pagination-bullet-active,
.carousel_customPagination__HxY3A.you-might-like-pagination
    > .swiper-pagination-bullet-active {
  background: #311C77;
}

.tabs_tabButtonList__EQ2Wu {
  display: flex;
  justify-content: center;
  border: 2px #ECF5F9 solid;
  border-bottom: none;
  border-radius: 16px 16px 0 0;
}

  @media (min-width: 1024px) {.tabs_tabButtonList__EQ2Wu {
    width: 50%
}
  }

.tabs_tabButton__gNXxb {
  all: unset;
  cursor: pointer;
  width: 50%;
  padding: 16px 8px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

@media (min-width: 1024px) {

.tabs_tabButton__gNXxb {
    padding: 24px 40px
}
  }

.tabs_tabButton__gNXxb[aria-selected='true'] {
  color: #311C77;
  background: #FFFFFF;
}

.tabs_tabButton__gNXxb[aria-selected='false'] {
  background: #ECF5F9;
}

.tabs_tabButtonRight__BjKqg {
  border-top-right-radius: 14px;
}

.tabs_tabButtonRight__BjKqg[aria-selected='true'] {
  border-top-left-radius: 14px;
  box-shadow: -5px -9px 17px -12px #b2c4ff;
}

.tabs_tabButtonRight__BjKqg[aria-selected='false'] {
  border-bottom-left-radius: 16px;
}

.tabs_tabButtonLeft__8Ogfg {
  border-top-left-radius: 14px;
}

.tabs_tabButtonLeft__8Ogfg[aria-selected='true'] {
  border-top-right-radius: 14px;
  box-shadow: 5px -9px 17px -12px #b2c4ff;
}

.tabs_tabButtonLeft__8Ogfg[aria-selected='false'] {
  border-bottom-right-radius: 16px;
}

.tabs_tabPanel__nq0dO {
  padding: 16px 8px;
  border: 2px #ECF5F9 solid;
  border-radius: 0 0 16px 16px;
  border-top: none;
}

@media (min-width: 768px) {

.tabs_tabPanel__nq0dO {
    padding: 16px 24px
}
  }

@media (min-width: 1024px) {

.tabs_tabPanel__nq0dO {
    position: relative;
    border-top-right-radius: 16px
}
  }

/* Pseudo-element which adds top border to panels 
   without disrupting tab buttons */
@media (min-width: 1024px) {
.tabs_tabPanel__nq0dO::after {
    content: '';
    position: absolute;
    top: -2px;
    right: 9px;
    border-top: 2px #ECF5F9 solid;
    height: 5px;
    width: calc(
      50% - 9px
    ) /* calc(.tabButtonList width - pseudo-element right position) */
}
  }

.tabs_tabPanelIsHidden__v8Mkd {
  display: none;
}

.help-me-choose_container__HHZRp {
  position: relative;
  padding: 48px 32px 32px;
  background: #5234B6;
  border-radius: 24px;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
}

  @media (min-width: 768px) {.help-me-choose_container__HHZRp {
    padding: 48px 64px 48px
}
  }

  @media (min-width: 1024px) {.help-me-choose_container__HHZRp {
    height: 330px;
    padding-top: 40px
}
  }

  @media (min-width: 1440px) {.help-me-choose_container__HHZRp {
    height: 361px;
    padding-bottom: 40px
}
  }

.help-me-choose_leftSection__A_pIc {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {

.help-me-choose_leftSection__A_pIc {
    max-width: 480px;
    flex: 0 0 auto
}
  }

@media (min-width: 1440px) {

.help-me-choose_leftSection__A_pIc {
    padding-top: calc(40px * 2);
    padding-bottom: calc(40px * 2)
}
  }

.help-me-choose_title__KUHof {
  padding-bottom: 32px;
  margin-top: 64px;
  color: #311C77;
  font-size: 28px;
  font-weight: 700;
}

@media (min-width: 1024px) {

.help-me-choose_title__KUHof {
    font-size: 34px
}
  }

.help-me-choose_heading__NJT8x {
  padding-bottom: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

@media (min-width: 1024px) {

.help-me-choose_heading__NJT8x {
    font-size: 28px;
    line-height: 40px
}
  }

.help-me-choose_body__4VW_r {
  padding-bottom: 16px;
  font-size: 18px;
  line-height: 26px;
}

@media (min-width: 1024px) {

.help-me-choose_body__4VW_r {
    padding-bottom: 32px;
    font-size: 22px;
    line-height: 32px
}
  }

.help-me-choose_image__oGEfi {
  width: 408px;
  height: 265px;
}

.help-me-choose_buttonContainer__SsozF {
  width: 100%;
}

@media (min-width: 768px) {

.help-me-choose_buttonContainer__SsozF {
    width: 220px
}
  }

@media (min-width: 1024px) {

.help-me-choose_buttonContainer__SsozF {
    width: 228px
}
  }

.value-propositions_imageContainer__rJjcW {
  width: 30px;
  height: 32px;
  margin-right: 8px;
}

  @media (min-width: 768px) {.value-propositions_imageContainer__rJjcW {
    margin-right: 20px
}
  }

  @media (min-width: 1024px) {.value-propositions_imageContainer__rJjcW {
    margin-right: 33px
}
  }

.value-propositions_root__feuOI {
  display: flex;
  flex-direction: row;
}

.value-propositions_heading__2enGU {
  margin-bottom: 8px;
  color: #311C77;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.value-propositions_body__Spxd4 {
  width: 230px;
  color: #656565;
  font-size: 18px;
  line-height: 26px;
}

@media (min-width: 375px) {

.value-propositions_body__Spxd4 {
    width: 280px
}
  }

@media (min-width: 768px) {

.value-propositions_body__Spxd4 {
    width: 390px
}
  }

@media (min-width: 1440px) {

.value-propositions_body__Spxd4 {
    width: 440px
}
  }

.value-propositions_icon__maOFO {
  width: 22px;
  height: 26px;
}

@media (min-width: 1024px) {

.value-propositions_icon__maOFO {
    width: 30px;
    height: 32px
}
  }

.usp-slide_slideCard__4mgwl {
  --background-color: #f8f6ff;
  --title-color: #5234b6;
  --icon-color: #8f1dbf;

  display: grid;
  height: 69px;
  padding: 2rem 1rem;
  margin: 1rem 0.5rem;
  grid-template: 1fr auto / 24px auto;
  grid-template-areas:
    'icon title'
    'icon body';
  grid-gap: 0.5rem 0.75rem;
  background-color: var(--background-color);
  border-radius: 8px;
}

  .usp-slide_slideCard__4mgwl:first-of-type {
    margin-left: 1rem;
  }

  @media screen and (min-width: 1024px) {

  .usp-slide_slideCard__4mgwl:first-of-type {
      margin-left: 0
  }
    }

  .usp-slide_slideCard__4mgwl:last-of-type {
    margin-right: 1rem;
  }

  @media screen and (min-width: 1024px) {

  .usp-slide_slideCard__4mgwl:last-of-type {
      margin-right: 0
  }
    }

  .usp-slide_slideCard__4mgwl h3 {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
    grid-area: title;
    font-size: 1.125rem;
    font-weight: 700;
    color: var(--title-color);
    margin: 0;
    padding-top: 3px;
  }

  .usp-slide_slideCard__4mgwl svg {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
    grid-area: icon;
    align-self: flex-start;
    color: var(--icon-color);
  }

  .usp-slide_slideCard__4mgwl p {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    grid-area: body;
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    padding-bottom: 0.688rem;
    line-height: 20px;
  }

  @media screen and (min-width: 1024px) {.usp-slide_slideCard__4mgwl {
    --background-color: transparent;

    max-width: 320px;
    padding: 0;
    margin: 0
}

    .usp-slide_slideCard__4mgwl h3 {
      text-decoration: underline;
    }
  }

.usp-container_uspContainerDesktop__wLw0X {
  display: none;
}

  @media screen and (min-width: 1024px) {.usp-container_uspContainerDesktop__wLw0X {
    display: grid;
    margin: 0;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4rem;
    margin-bottom: 1.5rem
}
  }

.usp-container_uspContainerMobile__3d7KR {
  display: block;
}

@media screen and (min-width: 1024px) {

.usp-container_uspContainerMobile__3d7KR {
    display: none
}
  }

.usp-container_carouselControls__EIEKd {
  padding: 1rem;
  margin-bottom: 1rem;
}

.usp-container_carouselWrapper__oryLx {
  margin-top: 1rem;
}

@media screen and (min-width: 1024px) {

.usp-container_carouselWrapper__oryLx {
    margin-top: 2rem
}
  }

.usp-container_carouselInner__JXCpN {
  padding: 0;
}

/** restore the wrapper's css for desktops **/

@media screen and (min-width: 1024px) {

.usp-container_carouselInner__JXCpN {
    padding-right: calc(32px + env(safe-area-inset-right));
    padding-left: calc(32px + env(safe-area-inset-left))
}
  }

.homepage_trustpilotScore__gO4qo {
  margin-top: 32px;
}

.homepage_heading__LffW5 {
  color: #311C77;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 24px;
  margin-top: 32px;
  font-size: 32px;
}

@media (min-width: 768px) {

.homepage_heading__LffW5 {
    margin-bottom: 32px;
    font-size: 42px
}
  }

.homepage_valuePropsCardsContainer__7XBR3 {
  display: grid;
  margin-top: 50px;
  grid-row-gap: 48px;
}

@media (min-width: 1024px) {

.homepage_valuePropsCardsContainer__7XBR3 {
    margin-top: 56px;
    -moz-column-gap: 16px;
         column-gap: 16px;
    grid-template-columns: repeat(2, 1fr)
}
  }

.homepage_zeroFox__c4ltv {
  display: none;
}

