.container {
  position: relative;
  color: var(--cds-color-monochrome-white);
  -webkit-font-smoothing: antialiased;
}

.background {
  width: 100%;
  margin-top: -120px;
  padding-bottom: 16px;

  @media only screen and (min-width: 1024px) {
    padding-bottom: 32px;
  }

  @media (--m) {
    padding-bottom: 48px;
  }
}

.backgroundGradient {
  background-image: linear-gradient(
    180deg,
    #311b77 0%,
    #8f1dbf 48%,
    #d333ec 98%
  );
}

/* Leaving commented so if the background gradient changes in future we can switch quickly
* if needed use cx in className to combine backgroundGradientForPromo with {[styles['backgroundGradientForPromo']]: isHeroPromotional,} */
.backgroundGradientForPromo {
  background-image: linear-gradient(180deg, #110a2a 0%, #8f1dbf 100%);
}

.heroInner {
  max-width: 1280px;
  position: relative;
  display: grid;
  grid-template: 'content' 'search' / 1fr;
  gap: 32px;
  margin: 0 auto;
  padding-top: 120px;
  padding-right: calc(16px + env(safe-area-inset-right));
  padding-left: calc(16px + env(safe-area-inset-left));

  @media only screen and (min-width: 768px) {
    padding-left: calc(36px + env(safe-area-inset-left));
    padding-right: calc(36px + env(safe-area-inset-left));
  }

  @media only screen and (min-width: 1024px) {
    grid-template: 'search . content' / 390px 36px auto;
    overflow: hidden;
    gap: 0;
  }
}

.textAndImageContainer {
  display: grid;
  grid-template: 'text' 'image' / 1fr;
  align-items: center;
  grid-area: content;
  gap: 32px;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    max-height: 458px;
    margin: 0 auto;
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  width: 100%;
  @media (--m) {
    gap: 8px;
  }
}

.imageGridContainer {
  display: grid;
}

.imageGridContainer img {
  max-height: 190px;

  @media screen and (min-width: 1024px) {
    max-height: 200px;
  }
}

.isMobileImageShown {
  gap: 32px;
}

.trustpilotWrapper {
  padding: 1rem;
}

.trustpilotMobile {
  padding-top: 24px;
}
