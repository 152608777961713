.innerWrapper {
  padding: 0 var(--cds-spacing-large-px-100) 0 var(--cds-spacing-medium-px-300);

  @media (--s) {
    padding: unset;
    text-align: center;
  }
}

.logoWrapper {
  margin: 0 0 var(--cds-spacing-giant-px-200);
  @media (--s) {
    margin: 0 auto var(--cds-spacing-giant-px-200);
  }
}

.logo {
  width: 141px;

  @media (--s) {
    width: 343px;
  }
}

.heading {
  font-size: 1.75rem;
  color: var(--cds-color-primary-deep-purple);
  margin-bottom: var(--cds-spacing-medium-px-300);
}

.cta {
  width: 279px;
  margin: 0 auto var(--cds-spacing-large-px-200) 0;
  @media (--s) {
    width: 343px;
    margin: 0 auto var(--cds-spacing-large-px-200);
  }
}
