.container {
  --radio-purple: #5234b6;

  background-color: var(--cds-color-monochrome-white);
  border-radius: 18px;
  color: var(--cds-color-monochrome-dark-grey);
  padding: 1.5rem 1.125rem;
  width: 100%;
  margin: 0 auto;
  grid-area: search;

  @media only screen and (min-width: 768px) {
    padding: 1.5rem 1.125rem;
  }

  @media only screen and (min-width: 1024px) {
    height: 458px;
    max-width: unset;
    margin: unset;
    padding: 1.5rem 2rem 2rem;
  }
}

.heroFormContainer {
  padding: 1rem 1.125rem 0;

  @media only screen and (min-width: 768px) {
    padding: 1rem 1.125rem 0;
  }

  @media only screen and (min-width: 1024px) {
    height: 430px;
    padding: 1rem 2rem 0;
  }
}

.heading {
  font-size: var(--ds-font-size-xl);
  font-weight: var(--ds-font-weight-bold);
  line-height: 2rem;
  color: var(--cds-color-monochrome-black);
  margin-bottom: 1.5rem;
}

.errorBox {
  display: none;
}

.toPriceSelect {
  margin-bottom: 1rem;
}

.radioGroup {
  display: inline-flex;
  padding: 1.125rem 0 1rem;
  gap: 2.5rem;
}

.priceLabel {
  white-space: nowrap;
  width: auto;
  display: flex;
  align-items: center;
  min-width: fit-content;
  gap: 1rem;
}

.radioInputContainer {
  display: flex;
  align-items: center;
  min-width: fit-content;

  & input[type='radio'] {
    appearance: none;
    margin: 0;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
    background-color: var(--cds-color-monochrome-white);

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      border: none;
    }

    &:not(:checked) {
      border: 1px solid black;
    }

    &:checked {
      background-color: var(--cds-color-monochrome-white);
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid var(--radio-purple);
    }

    &:checked::after {
      background-color: var(--radio-purple);
    }
  }
}

.buttonContainer {
  @media only screen and (min-width: 1024px) {
    margin-bottom: 1.5rem;
  }
}

.trustpilotOverview {
  display: none;

  @media only screen and (min-width: 1024px) {
    display: block;
  }
}

.helpMeChoose {
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  size: 14px;
  flex-direction: column;
  line-height: 20px;
  justify-content: center;

  @media only screen and (min-width: 480px) and (max-width: 1024px) {
    flex-direction: row;
    gap: 0.5ch;
  }
}

.link {
  --link-color: #1565c0;

  color: var(--link-color);
  text-decoration: underline;
}
