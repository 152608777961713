.container {
  margin-bottom: calc(var(--ds-space-5-x) * 2);
}

.partExchangeMarketingSectionContainer {
  position: relative;
  padding: var(--ds-space-4-x) var(--ds-space-2-x);
  margin-bottom: 32px;
  background: var(--ds-color-deepPurple);
  border-radius: 24px;

  @media (--m) {
    height: 362px;
    padding: 0;
  }
}

.partExchangeMarketingSectionWrapper {
  display: flex;
  flex-direction: column;

  @media (--m) {
    max-width: 1024px;
    flex-direction: row;
    margin: 0 0 0 86px;
  }
}

.partExchangeMarketingSectionLeftSection {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: var(--ds-space-3-x) 0 0 0;

  @media (--m) {
    max-width: 416px;
    flex: 0 0 auto;
    padding: var(--ds-space-6-x) 0 0 0;
  }
}

.partExchangeMarketingSectionTitle {
  color: var(--ds-color-goGreen);
}

.partExchangeMarketingSectionSubTitle {
  margin-bottom: var(--ds-space-3-x);
  color: var(--ds-color-pampas);
}

.partExchangeMarketingSectionSubTitleSpan {
  white-space: nowrap;
}

.vrmInputWrapper {
  margin-bottom: var(--ds-space-5-x);
}

.vrmInputLabel {
  margin-bottom: var(--ds-space-1-and-a-half-x);
  color: var(--ds-color-pureWhite);
}

.vrmInput {
  border: 1px solid var(--ds-color-vividViolet);
  border-radius: 4px;
}

.partExchangeMarketingSectionImage {
  display: none;
  @media (--m) {
    position: absolute;
    right: 0;
    display: block;
    overflow: hidden;
    width: 410px;
    height: 362px;
    border-radius: 0 24px 24px 0;
  }
}

.findMoreButton {
  margin-left: var(--ds-space-1-and-a-half-x);
  color: var(--ds-color-toreaBay);
  text-decoration: underline;
}

.infoWrapper {
  text-align: center;
}

.findMore {
  display: flex;
  justify-content: center;
}
