.card {
  display: block;
  padding: var(--ds-space-2-x) var(--ds-space-1-x) 24px var(--ds-space-1-x);
  background-color: var(--ds-color-pampas);
  border-radius: 12px;
  box-shadow: 0 3px 10px 0 rgb(169 169 169 / 30.2%);
  text-align: center;

  @media (--xxs) {
    padding: var(--ds-space-1-and-a-half-x) 14px;
  }

  @media (--s) {
    padding: var(--ds-space-2-x) 14px 25px 14px;
  }
}

.card:hover {
  background-color: var(--color-pureWhite);
}

.heading {
  margin-top: var(--ds-space-2-x);
  text-align: center;
}

.title {
  color: var(--ds-color-mineShaft);
  font-size: var(--ds-font-size-m);
  font-weight: var(--ds-font-weight-bold);
  line-height: 26px;
}

.body {
  margin-top: 2px;
  margin-bottom: var(--ds-space-1-and-a-half-x);
  color: var(--ds-color-dove);
  font-size: var(--ds-font-size-m);
  font-weight: var(--ds-font-weight-medium);
  line-height: 26px;

  @media (--m) {
    margin-top: var(--ds-space-half-x);
    margin-bottom: var(--ds-space-3-x);
  }
}

.link {
  color: var(--ds-color-toreaBay);
  font-size: var(--ds-font-size-s);
  font-weight: var(--ds-font-weight-semibold);
  text-decoration: underline;
}

.button {
  width: 33px;
  height: 40px;
  padding: var(--ds-space-1-x) var(--ds-space-2-x);
}
