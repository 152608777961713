.partExContainer {
  --radio-purple: #5234b6;

  background-color: var(--cds-color-monochrome-white);
  border-radius: 18px;
  color: var(--cds-color-monochrome-dark-grey);
  padding: 1.5rem 1.125rem 1rem;
  width: 100%;
  margin: 0 auto;
  grid-area: search;

  @media only screen and (min-width: 1024px) {
    height: 430px;
    max-width: unset;
    margin: unset;
    padding: 1.5rem 2rem 0;
  }
}

.heading {
  font-size: var(--ds-font-size-xl);
  font-weight: var(--ds-font-weight-bold);
  line-height: 2rem;
  color: var(--cds-color-monochrome-black);
  margin-bottom: 2rem;
}

.vrmInputLabel {
  --vrmInputLabel: #2f2f2f;

  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: var(--ds-space-1-and-a-half-x);
  color: var(--vrmInputLabel);
}

.vrmInput {
  --borderVrmInput: #656565;

  border: 1px solid var(--borderVrmInput);
  border-radius: 4px;
}

.partExTextList {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 2.5rem;
  padding-left: 8px;
}

.partExTextListItems {
  display: flex;
  align-items: center;
  gap: 1rem;
}
