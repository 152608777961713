.whyCinchContainer {
  padding: var(--ds-space-8-x) 0 var(--ds-space-7-x) 0;
  margin-bottom: var(--ds-space-8-x);
  background-color: var(--ds-color-morningFrost);

  @media (--s) {
    padding-bottom: var(--ds-space-4-x);
  }

  @media (--m) {
    padding: calc(var(--ds-space-1-x) * 9) 0 var(--ds-space-7-x);
    margin-bottom: var(--ds-space-12-x);
  }
}

.whyCinchInner {
  max-width: 1024px;
  padding-right: calc(var(--ds-space-2-x) + env(safe-area-inset-right));
  padding-left: calc(var(--ds-space-2-x) + env(safe-area-inset-left));
  margin: 0 auto;

  @media (--s) {
    padding-right: calc(
      calc(var(--ds-space-1-x) * 15) + env(safe-area-inset-left)
    );
    padding-left: calc(
      calc(var(--ds-space-1-x) * 15) + env(safe-area-inset-right)
    );
  }

  @media (--m) {
    padding-right: calc(var(--ds-space-4-x) + env(safe-area-inset-right));
    padding-left: calc(var(--ds-space-4-x) + env(safe-area-inset-left));
  }
}

.whyCinchHeading {
  margin-bottom: var(--ds-space-7-x);
  text-align: center;

  @media (--m) {
    text-align: left;
  }
}

.contentWrapper {
  @media (--m) {
    display: flex;
  }
}

.cardWithIcon {
  padding: var(--ds-space-4-x);
  margin: 0 auto var(--ds-space-3-x) auto;

  @media (--m) {
    margin: 0;
  }
}

.whyCinchImageContainer {
  width: 300px;
  margin-top: var(--ds-space-3-x);
  @media (--m) {
    min-width: 360px;
    margin-left: var(--ds-space-5-x);
  }

  @media (--l) {
    margin-top: 0;
    margin-right: -80px;
  }
}

.contentSectionWrapper {
  margin-bottom: 0;
  text-align: center;

  @media (--m) {
    text-align: left;
  }
}

.contentSectionTitle {
  margin-bottom: var(--ds-space-1-x);
  font-size: 20px;
  line-height: var(--ds-font-line-height-display);
}

.contentSectionBody {
  color: var(--ds--color-pureBlack);
  font-size: var(--ds-font-size-m);
}

.bulletContainer {
  @media (--m) {
    align-self: flex-start;
  }
}

.listItem {
  @media (--m) {
    margin-bottom: var(--ds-space-8-x);
  }
}
