.vehicleCardList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: var(--ds-space-2-x); /* 24px - 8px padding around cards */

  @media (--m) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.vehicleCardListItem {
  height: 100%;
}

.vehicleCardContainer {
  height: 100%;
  padding: var(--ds-space-1-x);
}

.findSimilarVehiclesCard {
  height: 100%;
  min-height: 370px;
  border-radius: var(--ds-space-2-x);
  background: var(--ds-color-meteorite);
  padding: 0 var(--ds-space-4-x);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.textContainer {
  margin-top: 88px;
  width: 226px;
}

.text {
  color: var(--ds-color-pureWhite);
  margin-top: var(--ds-space-3-x);
  font-size: var(--ds-font-size-l);
}

.button {
  padding: 0;
  width: 100%;
  margin-bottom: var(--ds-space-4-x);
}
