.heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  @media (--m) {
    font-size: 42px;
    line-height: 56px;
  }
}
