.ourCommitmentSlideContainer {
  display: flex;
  height: 450px;
  box-sizing: border-box; /* Opera/IE 8+ */
  justify-content: center;
  padding: calc(var(--ds-space-1-x) * 12) calc(var(--ds-space-1-x) * 2) 0
    calc(var(--ds-space-1-x) * 2);

  @media (--s) {
    height: 400px;
    padding-top: calc(var(--ds-space-1-x) * 13);
    margin: 0 auto;
    text-align: center;
  }
}

.ourCommitmentSlide {
  max-width: 630px;
}

.title {
  color: var(--ds-color-goGreen);
  font-size: var(--ds-font-size-xxl) !important;
}

.body {
  color: var(--ds-color-pureWhite);

  a {
    color: var(--ds-color-pureWhite) !important;
  }
}
