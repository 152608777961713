.imageContainer {
  display: flex;
  margin: 0 auto;
  max-width: 70%;

  @media only screen and (max-width: 1024px) {
    display: none;

    &.isShownOnMobile {
      display: flex;
    }
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    max-width: unset;
  }
}

/* stylelint-disable-next-line block-no-empty */
.isShownOnMobile {
}
