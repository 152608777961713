.imageContainer {
  width: 30px;
  height: 32px;
  margin-right: var(--ds-space-1-x);

  @media (--s) {
    margin-right: 20px;
  }

  @media (--m) {
    margin-right: 33px;
  }
}

.root {
  display: flex;
  flex-direction: row;
}

.heading {
  margin-bottom: var(--ds-space-1-x);
  color: var(--ds-color-deepPurple);
  font-size: var(--ds-font-size-m);
  font-weight: var(--ds-font-weight-semibold);
  line-height: 26px;
}

.body {
  width: 230px;
  color: var(--ds-color-dove);
  font-size: var(--ds-font-size-m);
  line-height: 26px;

  @media (--xxxs) {
    width: 280px;
  }

  @media (--s) {
    width: 390px;
  }
  @media (--l) {
    width: 440px;
  }
}

.icon {
  width: 22px;
  height: 26px;

  @media (--m) {
    width: 30px;
    height: 32px;
  }
}
