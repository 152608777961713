.tabButtonList {
  display: flex;
  justify-content: center;
  border: 2px var(--cds-color-secondary-neutral) solid;
  border-bottom: none;
  border-radius: var(--ds-space-2-x) var(--ds-space-2-x) 0 0;

  @media (--m) {
    width: 50%;
  }
}

.tabButton {
  all: unset;
  cursor: pointer;
  width: 50%;
  padding: var(--ds-space-2-x) var(--ds-space-1-x);
  font-size: var(--ds-font-size-m);
  font-weight: 700;
  text-align: center;

  @media (--m) {
    padding: var(--ds-space-3-x) var(--ds-space-5-x);
  }
}

.tabButton[aria-selected='true'] {
  color: var(--ds-color-deepPurple);
  background: var(--ds-color-pureWhite);
}

.tabButton[aria-selected='false'] {
  background: var(--cds-color-secondary-neutral);
}

.tabButtonRight {
  border-top-right-radius: 14px;
}

.tabButtonRight[aria-selected='true'] {
  border-top-left-radius: 14px;
  box-shadow: -5px -9px 17px -12px #b2c4ff;
}

.tabButtonRight[aria-selected='false'] {
  border-bottom-left-radius: var(--ds-space-2-x);
}

.tabButtonLeft {
  border-top-left-radius: 14px;
}

.tabButtonLeft[aria-selected='true'] {
  border-top-right-radius: 14px;
  box-shadow: 5px -9px 17px -12px #b2c4ff;
}

.tabButtonLeft[aria-selected='false'] {
  border-bottom-right-radius: var(--ds-space-2-x);
}

.tabPanel {
  padding: var(--ds-space-2-x) var(--ds-space-1-x);
  border: 2px var(--cds-color-secondary-neutral) solid;
  border-radius: 0 0 var(--ds-space-2-x) var(--ds-space-2-x);
  border-top: none;

  @media (--s) {
    padding: var(--ds-space-2-x) var(--ds-space-3-x);
  }

  @media (--m) {
    position: relative;
    border-top-right-radius: var(--ds-space-2-x);
  }
}

/* Pseudo-element which adds top border to panels 
   without disrupting tab buttons */
.tabPanel::after {
  @media (--m) {
    content: '';
    position: absolute;
    top: -2px;
    right: 9px;
    border-top: 2px var(--cds-color-secondary-neutral) solid;
    height: 5px;
    width: calc(
      50% - 9px
    ); /* calc(.tabButtonList width - pseudo-element right position) */
  }
}

.tabPanelIsHidden {
  display: none;
}
