.controlsWrapper {
  margin-top: var(--ds-space-2-x);

  @media (--m) {
    margin-top: var(--ds-space-3-x);
  }
}

/* stylelint-disable selector-class-pattern */
.customPagination:global(.recently-viewed-cars-pagination
    > .swiper-pagination-bullet),
.customPagination:global(.you-might-like-pagination
    > .swiper-pagination-bullet) {
  border-color: var(--ds-color-deepPurple);
}

.customPagination:global(.recently-viewed-cars-pagination
    > .swiper-pagination-bullet-active),
.customPagination:global(.you-might-like-pagination
    > .swiper-pagination-bullet-active) {
  background: var(--ds-color-deepPurple);
}
