.heading {
  margin-top: var(--ds-space-6-x);
  color: var(--ds-color-mineShaft);
  font-weight: var(--ds-font-weight-bold);
  line-height: var(--ds-space-5-x);
  @media (--m) {
    margin-top: var(--ds-space-8-x);
  }
}

.container {
  margin-top: 33px;
  margin-bottom: var(--ds-space-8-x);
  @media (--m) {
    margin-top: var(--ds-space-3-x);
    margin-bottom: var(--ds-space-12-x);
  }
}

.cards {
  display: grid;
  border-radius: var(--ds-space-2-x);
  column-gap: var(--ds-space-2-x);
  grid-template-columns: repeat(2, 1fr);
  row-gap: var(--ds-space-2-x);

  @media (--s) {
    column-gap: var(--ds-space-6-x);
    grid-template-columns: repeat(3, 1fr);
    row-gap: var(--ds-space-3-x);
  }

  @media (--m) {
    grid-template-columns: repeat(4, 1fr);
  }
}
