/* stylelint-disable selector-class-pattern */
.customPagination:global(.cinch-commitment-pagination
    > .swiper-pagination-bullet) {
  border-color: var(--ds-color-pureWhite);
}

.customPagination:global(.cinch-commitment-pagination
    > .swiper-pagination-bullet-active) {
  background: var(--ds-color-pureWhite);
}

.customPagination:global(.cinch-commitment-pagination > .swiper-wrapper) {
  box-sizing: inherit;
}

.controlsWrapper {
  z-index: 1;
  top: -80px;
  padding: 0 var(--ds-space-2-x);
  text-align: center;

  @media (--s) {
    padding: 0;
  }
}

.prevButton,
.nextButton {
  border: 1px var(--ds-color-pureWhite) solid !important;
  color: var(--ds-color-pureWhite) !important;
}
